// src/lib/date-extension.ts
Date.prototype.isDeserializable = function (str: string): boolean {
  const iso8601Regex =
    /^(\d{4})-(\d{2})-(\d{2})([Tt](\d{2}):(\d{2}):(\d{2})(\.\d+)?([Zz]|([+-])(\d{2}):(\d{2})))?$/;
  return iso8601Regex.test(str);
};

Date.prototype.deserialize = function (str: string): Date {
  return new Date(str);
};

Date.prototype.serialize = function (): string {
  return this.toISOString();
};
