import { useEffect, useState } from "react";

const API_MOCKING =
  (process.env.NEXT_PUBLIC_API_MOCKING || "disabled") === "enabled";

export default function useMock() {
  const [shouldRender, setShouldRender] = useState(!API_MOCKING);

  useEffect(() => {
    async function initMocks() {
      const { prepare } = await import("../mocks");
      await prepare();
      setShouldRender(true);
    }

    if (API_MOCKING) {
      initMocks();
    }
  }, []);

  return [shouldRender];
}
