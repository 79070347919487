import * as Yup from "yup";
import { boolean, Schema, string, StringSchema } from "yup";
import { YupMessageParameter } from "@/features/ui/form/use-yup-helper";

Yup.addMethod(
  // @ts-ignore
  Schema,
  "requiredIf",
  function ({
    is,
    message,
  }: {
    is: (() => boolean) | boolean;
    message: string | ((parameter: YupMessageParameter) => string);
  }) {
    // @ts-ignore
    return this.test({
      name: "requiredIf",
      exclusive: false,
      message,
      // @ts-ignore
      test(value) {
        const { path, createError } = this;
        return (typeof is === "function" ? is() : is) || value
          ? true
          : createError({ path, message });
      },
    }).meta({ is: is });
  }
);
