import { isSerializable } from "@/features/ui/app.type";

type Type = "SNAKE" | "CAMEL";

function withConvert(type: Type) {
  if (type === "SNAKE") {
    return (str: string) => {
      return str
        .split("__")
        .map((item) => {
          return item.replace(/([A-Z])/g, "_$1").toLowerCase();
        })
        .join("__");
    };
  } else {
    //"CAMEL"
    return (str: string) => {
      return str
        .split("__")
        .map((item) => {
          return item.replace(/_([a-z])/g, (m, p1, offset) => {
            return p1.toUpperCase();
          });
        })
        .join("__");
    };

    // return (str: string) => {
    //   const value = str.replace(/_([a-z])/g, (m, p1, offset) => {
    //     if (str[offset - 1] === "_") {
    //       return m;
    //     }
    //     return p1.toUpperCase();
    //   });
    //
    // todo 이 예외는 어디서 쓰는걸까?.. 찾아봐야함
    //   if (str.startsWith("_")) {
    //     return "_" + value[0].toLowerCase() + value.substring(1);
    //   }
    //   return value;
    // };
  }
}

function convertByType(type: Type, obj: any): typeof obj {
  return convert(withConvert(type), obj);
}

function convert(keyFunc: (str: string) => string, obj: any): typeof obj {
  if (Array.isArray(obj)) {
    return obj.map((item) => convert(keyFunc, item));
  } else if (typeof obj === "object" && obj !== null) {
    if (obj instanceof Date || obj instanceof File || isSerializable(obj)) {
      return obj;
    }

    return Object.keys(obj).reduce((acc: any, key: string) => {
      const convertedKey = keyFunc(key);
      const value = obj[key];
      acc[convertedKey] = convert(keyFunc, value);
      return acc;
    }, {});
  }
  return obj;
}

export function toSnakeKeyObject(obj: any): typeof obj {
  return convertByType("SNAKE", obj);
}

export function toCamelKeyObject(obj: any): typeof obj {
  return convertByType("CAMEL", obj);
}

export function toCamel(str: string) {
  return withConvert("CAMEL")(str);
}

export function toSnake(str: string) {
  return withConvert("SNAKE")(str);
}

export function toTitle(str: string) {
  // Check if every character is uppercase or non-alphabetic
  if (/^[^a-z]*$/.test(str)) {
    return str; // Return the string as is
  }

  return str.replace(/(?:^|\s|:)\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function toSentence(str: string) {
  return str
    .toLowerCase() // First, make the entire string lowercase
    .replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (letter) {
      // This regular expression looks for either:
      // a) the first word in the string, or
      // b) a word that follows a period, exclamation point, or question mark (and optional whitespace)
      // It then capitalizes the first character of this word.
      return letter.toUpperCase();
    });
}

export function toUpper(str: string) {
  return str.toUpperCase();
}

export function toLower(str: string) {
  return str.toLowerCase();
}
