import { Global } from "@emotion/react";

export default function Fonts() {
  return (
    <Global
      styles={`
      @font-face {
        font-family: 'Noto Sans CJK KR';
        font-weight: 350,
        font-style: normal,
        src: url("./fonts/NotoSansKR-WEB/NotoSansKR-Regular.woff") format('woff');
      }
     `}
    />
  );
}
