import { OrderSheet } from "@/features/order-sheets/order-sheet.type";
import { LineSheet } from "@/features/line-sheet-sets/line-sheet-set.types";
import { camelCase, snakeCase } from "lodash";
import { toLower } from "@/utils/case";
import { Deserializable, Serializable } from "@/features/ui/app.type";
import { string } from "yup";
import { Deserializer } from "node:v8";

export interface Company {
  id: number;
  name: string;
  type: CompanyType;
}

export interface LightCompany {
  id: number;
  name: string;
}

export type CompanyType =
  | "BOUTIQUE"
  | "BUYER"
  | "AGENCY"
  | "BRAND"
  | "CROSS_TRADER";

export const CompanyTypes: CompanyType[] = [
  "BOUTIQUE",
  "BUYER",
  "AGENCY",
  "BRAND",
  "CROSS_TRADER",
];

export function isCompanyType(candidate: any): candidate is CompanyType {
  return CompanyTypes.includes(candidate);
}

export interface Brand extends Omit<Company, "type"> {
  type: "BRAND";
}

export interface Boutique extends Omit<Company, "type"> {
  type: "BOUTIQUE";
}

export interface Buyer extends Omit<Company, "type"> {
  type: "BUYER";
}

export interface Agency extends Omit<Company, "type"> {
  type: "AGENCY";
}

export interface CrossTrader extends Omit<Company, "type"> {
  type: "CROSS_TRADER";
}

export function getCompanySuffix(type: CompanyType): string {
  switch (type) {
    case "BOUTIQUE":
      return "BT";
    case "BUYER":
      return "BY";
    case "AGENCY":
      return "A";
    case "BRAND":
      return "BR";
    case "CROSS_TRADER":
      return "T";
  }
}

export interface ProductPrice {
  amount: number;
  currency: string;
}

export interface QuantityWithOption {
  name: string;
  quantity: number;
}

export interface StoredObject {
  id: number;
  name: string;
  url: string | null;
  createdAt: Date | null;
}

export interface TransientStoredObject {
  id: number;
  file: File;
}

export function isStoredObject(
  item: File | Pick<StoredObject, "id">
): item is StoredObject {
  if (item && typeof item === "object") {
    const object = item as StoredObject;
    return object.id !== undefined;
  }
  return false;
}

export function isTransientStoredObject(
  item: File | Pick<StoredObject, "id"> | TransientStoredObject
): item is TransientStoredObject {
  if (typeof item === "object") {
    const object = item as TransientStoredObject;
    return object.file !== undefined;
  }
  return false;
}

export function isFile(item: File | Pick<StoredObject, "id">): item is File {
  return item instanceof File;
}

export function getFileNameWithoutExtension(
  raw: File | string | Pick<StoredObject, "id" | "name">
) {
  let fullName;

  if (raw instanceof File) {
    fullName = raw.name;
  } else if (typeof raw === "object") {
    fullName = raw.name;
  } else {
    fullName = raw;
  }

  const lastDot = fullName.lastIndexOf(".");
  if (lastDot !== -1) {
    return fullName.substring(0, lastDot);
  }
  return fullName;
}

export function getFileExtension(fileOrFileName: File | string) {
  const name =
    fileOrFileName instanceof File ? fileOrFileName.name : fileOrFileName;
  const lastDot = name.lastIndexOf(".");
  if (lastDot !== -1) {
    return name.substring(lastDot + 1);
  }
  return "";
}

export function isExcel(
  item: File | Pick<StoredObject, "id" | "name">
): boolean {
  return ["xls", "xlsx", "csv", "xlsm", "xlsb"].some((ext) =>
    toLower(item.name).endsWith(ext)
  );
}

export function isPDF(item: File | Pick<StoredObject, "id" | "name">): boolean {
  return ["pdf"].some((ext) => toLower(item.name).endsWith(ext));
}

export interface PreSignedStoredObject {
  id: number;
  name: string;
  url: string;
}

export interface PageNumberBasedListRequest {
  pageNumber: number;
  pageSize: number;
}

export interface PageNumberBasedListResponse {
  totalCount: number;
}

export interface CursorBasedListRequest {
  pageSize: number;
  pageToken?: string;
}

export interface CursorBasedListResponse {
  nextPageToken: string | null;
}

export interface IdentityParameter {
  by: CompanyType;
  companyId: number;
}

export interface ViewLineSheet extends LineSheet {
  orderSheet?: OrderSheet;
}

export interface TransientFile<Type = any> {
  id: string;
  binary: File;
  type?: Type;
  number1?: number | "";
}

export type SortOrder = "ascend" | "descend";

export class OrderByItem extends Deserializable implements Serializable {
  readonly field: string;
  readonly sortOrder?: SortOrder;

  constructor(field: string, sortOrder: SortOrder = "ascend") {
    super();
    this.field = field;
    this.sortOrder = sortOrder;
  }

  serialize(): string {
    let prefix = "";
    if (this.sortOrder === "descend") {
      prefix = "-";
    }
    return `${prefix}${snakeCase(this.field)}`;
  }

  static deserialize(str: string) {
    let field = str;
    let sortOrder: SortOrder = "ascend";
    if (str.startsWith("-")) {
      field = str.substring(1);
      sortOrder = "descend";
    }
    return new OrderByItem(camelCase(field), sortOrder);
  }
}

export function isOrderByItem(obj: any): obj is OrderByItem {
  const candidate = obj as OrderByItem;
  return (
    typeof candidate.field !== "undefined" && isSortOrder(candidate.sortOrder)
  );
}

export function isOrderBy(obj: any): obj is OrderByItem[] {
  if (Array.isArray(obj)) {
    return obj.every((i) => isOrderByItem(i));
  }
  return false;
}

function isSortOrder(obj: any): obj is SortOrder {
  if (obj === undefined) {
    return true;
  } else if (typeof obj === "string") {
    return obj === "ascend" || obj === "descend";
  }
  return false;
}

export function serializeOrderBy(orderBy: OrderByItem): string;
export function serializeOrderBy(orderBy: OrderByItem[]): string[];
export function serializeOrderBy(orderBy: OrderByItem | OrderByItem[]): any {
  const func = (obj: OrderByItem) => {
    let prefix = "";
    if (obj.sortOrder === "descend") {
      prefix = "-";
    }
    return `${prefix}${snakeCase(obj.field)}`;
  };

  if (Array.isArray(orderBy)) {
    return orderBy.map((i) => func(i));
  } else {
    return func(orderBy);
  }
}

export interface LightUser {
  id: number;
  name: string;
  email: string;
}

export interface Price {
  value: number;
  currency: "EUR" | "KRW" | "EA";
}

export function isPrice(value: any): value is Price {
  return value && value.currency && typeof value.value === "number";
}

export function getCurrencySymbol(currency: "EUR" | "KRW" | "EA" | "%") {
  if (currency === "EUR") {
    return "€";
  } else if (currency === "KRW") {
    return "₩";
  } else if (currency === "EA") {
    return "EA";
  } else if (currency === "%") {
    return "%";
  }
}

export type FileType = "pdf" | "excel";

export function getMIMEType(fileType: FileType): string | string[] {
  switch (fileType) {
    case "pdf":
      return "application/pdf";
    case "excel":
      return [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
  }
}

export function getAccept(fileType?: FileType[]): string | undefined {
  if (fileType) {
    return fileType.map((type) => getMIMEType(type)).join(",");
  }
}
