import { useCallback } from "react";
import { useTranslation, Trans } from "react-i18next";
import { TOptions } from "i18next/typescript/options";
import { toSentence, toSnake, toTitle, toUpper } from "@/utils/case";
import { DateTime, DateTimeFormatOptions } from "luxon";
import {
  ConditionType,
  getConditionTypeSymbol,
} from "@/features/order-sheets/order-sheet.type";
import { uniq } from "lodash";
import { DateTimeString, DateUtils } from "@/features/ui/utils/date-utils";
import { isPrice, Price } from "@/features/types";

function getLocaleByLanguage(lng: string) {
  const obj: { [key: string]: string } = {
    en: "en-US",
    ko: "ko-KR",
    it: "it-IT",
  };

  return obj[lng] || "en-US";
}

export default function useI18nHelper() {
  const { t, i18n } = useTranslation();

  const tWithFuc = useCallback(
    (key: string, func?: (source: string) => string, option?: TOptions) => {
      const r = t(key, option);
      if (func) {
        return func(r);
      }
      return r;
    },
    [t]
  );

  const tWithPrefix = useCallback(
    (
      prefix: string,
      key: string,
      func?: (source: string) => string,
      option?: TOptions
    ): string => {
      const r = t(`${prefix}.${toSnake(key.toLowerCase())}`, option);
      if (func) {
        return func(r);
      }
      return r;
    },
    [t]
  );

  const tLocalDateString = useCallback(
    (jsDate?: Date | null) => {
      const locale = getLocaleByLanguage(i18n.language || "en");

      if (!jsDate) {
        return "";
      }

      if (locale === "ko-KR") {
        return DateTime.fromJSDate(jsDate)
          .setLocale(locale)
          .toFormat("yyyy-MM-dd");
      }

      return DateTime.fromJSDate(jsDate)
        .setLocale(locale)
        .toLocaleString(DateTime.DATE_SHORT);
    },
    [i18n]
  );

  const tLocalDateTimeString = useCallback(
    (jsDate: Date | null) => {
      const locale = getLocaleByLanguage(i18n.language || "en");

      if (jsDate === null) {
        return "";
      }

      if (locale === "ko-KR") {
        return DateTime.fromJSDate(jsDate)
          .setLocale(locale)
          .toFormat("yyyy-MM-dd(ccc) HH:mm:ss");
      }

      return DateTime.fromJSDate(jsDate)
        .setLocale(locale)
        .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
    [i18n]
  );

  //앤 규칙이 없어; 이상해 그냥 2로 할래
  const tLocalDateTimeString2 = useCallback(
    (jsDate?: Date | DateTimeString | null) => {
      const locale = getLocaleByLanguage(i18n.language || "en");

      if (!jsDate) {
        return "";
      }

      if (typeof jsDate === "string") {
        jsDate = DateUtils.toDate(jsDate);
      }

      if (locale === "ko-KR") {
        return DateTime.fromJSDate(jsDate)
          .setLocale(locale)
          .toFormat("yyyy-MM-dd(ccc) HH:mm");
      }

      let option: Intl.DateTimeFormatOptions = {
        ...DateTime.DATETIME_SHORT_WITH_SECONDS,
        second: undefined,
      };

      return DateTime.fromJSDate(jsDate)
        .setLocale(locale)
        .toLocaleString(option);
    },
    [i18n]
  );

  type CurrencyStringOption = {
    type: "CODE" | "SYMBOL" | "NONE";
    code?: string;
  };

  const tCurrencyString = useCallback(
    (amountOrPrice: number | "-" | Price, option?: CurrencyStringOption) => {
      if (amountOrPrice === "-") {
        return "-";
      }

      const amount =
        typeof amountOrPrice === "number" ? amountOrPrice : amountOrPrice.value;

      const actualConfig: CurrencyStringOption = {
        type: "SYMBOL",
        code: "EUR",
        ...option,
      };

      if (isPrice(amountOrPrice)) {
        actualConfig.code = amountOrPrice.currency;
      }

      if (actualConfig.type === "NONE") {
        return `${i18n.format(amount, "number")}`;
      } else if (actualConfig.type === "CODE") {
        return `${i18n.format(amount, "number")} ${actualConfig.code}`;
      } else if (actualConfig.type === "SYMBOL") {
        return `${i18n.t("common:intl_currency", {
          val: amount,
          currency: actualConfig.code,
        })}`;
      }
      return "";
    },
    [i18n]
  );

  const tConditionAppliedCurrencyString = useCallback(
    (
      conditionType: ConditionType | ConditionType[] | null,
      amount: number | "-",
      option?: CurrencyStringOption
    ): string => {
      if (amount === "-") {
        return "-";
      }

      let symbol: string = "";

      if (Array.isArray(conditionType)) {
        const uniqConditionTypes = uniq(conditionType);
        symbol =
          uniqConditionTypes.length === 1
            ? getConditionTypeSymbol(uniqConditionTypes[0])
            : getConditionTypeSymbol("MIX");
      } else {
        symbol = getConditionTypeSymbol(conditionType);
      }

      return `(${symbol}) ${tCurrencyString(amount, option)}`;
    },
    [tCurrencyString]
  );

  const tTitle = useCallback(
    (key: string, option?: TOptions) => {
      return toTitle(t(key, option));
    },
    [t]
  );

  const tSentence = useCallback(
    (key: string, option?: TOptions) => {
      return toSentence(t(key, option));
    },
    [t]
  );

  const tUpper = useCallback(
    (key: string) => {
      return toUpper(t(key));
    },
    [t]
  );

  const tNumberString = useCallback(
    (value: number) => {
      // const formatter = new Intl.NumberFormat(i18n.language, {
      //   style: "decimal",
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2,
      // });
      // return formatter.format(value);
      return `${i18n.format(value, "number")}`;

      // return `${i18n.format(value, "number", i18n.language, {})}`;
    },
    [i18n]
  );

  const tCountString = useCallback(
    (value: number, unit: string = "EA") => {
      return `${i18n.format(value, "number")} ${unit}`;
    },
    [i18n]
  );

  return {
    tWithFuc,
    tWithPrefix,
    tLocalDateString,
    tLocalDateTimeString,
    tLocalDateTimeString2,
    tCurrencyString,
    tConditionAppliedCurrencyString,
    tNumberString,
    tCountString,
    t,
    tTitle,
    tSentence,
    tUpper,
    i18n,
    Trans,
  };
}
