/**
 * todo 이클래스 상속시에 수정해야하는 부분 명시
 */
export interface Serializable {
  serialize(): string;
}

/**
 * todo 이클래스 상속시에 수정해야하는 부분 명시
 */
export abstract class Deserializable {
  deserialize(str: string): Deserializable {
    throw new Error("Method not implemented");
  }

  isDeserializable(str: string): boolean {
    throw new Error("Method not implemented");
  }
}

export function isSerializable(obj: any): obj is Serializable {
  return (
    obj !== undefined &&
    obj.serialize !== undefined &&
    typeof obj.serialize === "function"
  );
}

class Foo {
  private readonly _aString: string;

  constructor(aString: string) {
    this._aString = aString;
  }

  get aString() {
    return this._aString;
  }

  //
  [key: string]: any;
}

Object.defineProperty(Foo.prototype, "aString", {
  get: function () {
    return this._aString;
  },
  enumerable: true,
});
