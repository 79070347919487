export type DateTimeString = string &
  `${number}-${number}-${number} ${number}:${number}:${number}`;

function isDateTimeString(value: string): value is DateTimeString {
  return /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(value);
}

export class DateUtils {
  static deconstruct(date: Date) {
    return {
      year: date.getFullYear(),
      month: (date.getMonth() + 1).toString().padStart(2, "0"),
      day: date.getDate().toString().padStart(2, "0"),
      hours: date.getHours().toString().padStart(2, "0"),
      minutes: date.getMinutes().toString().padStart(2, "0"),
      seconds: date.getSeconds().toString().padStart(2, "0"),
      milliseconds: date.getMilliseconds().toString().padStart(3, "0"),
    };
  }

  static toDateTimeString(date: Date): DateTimeString {
    const { year, month, day, hours, minutes, seconds } =
      DateUtils.deconstruct(date);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}` as DateTimeString;
  }

  static isEqual(
    date1: Date | DateTimeString,
    date2: Date | DateTimeString
  ): boolean {
    const value1 =
      date1 instanceof Date ? DateUtils.toDateTimeString(date1) : date1;
    const value2 =
      date2 instanceof Date ? DateUtils.toDateTimeString(date2) : date2;
    return value1 === value2;
  }

  static toDate(dateTimeString: DateTimeString): Date {
    if (!isDateTimeString(dateTimeString)) {
      //throw new Error("Invalid date time string");
      //todo 이걸 어떻게 처리해야할지 고민해봐야겟구만
      console.error("Invalid date time string");
      return new Date();
    }
    const [date, time] = dateTimeString.split(" ");
    const [year, month, day] = date.split("-").map(Number);
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }

  static convertDateIgnoringSourceTimezone(
    date: Date,
    targetTimezoneOrOffset: string
  ): Date {
    // Get the components of the original date
    const { year, month, day, hours, minutes, seconds, milliseconds } =
      DateUtils.deconstruct(date);
    // Create the date string without timezone information
    const dateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

    let targetDate;

    if (targetTimezoneOrOffset.includes(":")) {
      // Target is an offset in the format +02:00 or -05:00
      targetDate = new Date(`${dateString}${targetTimezoneOrOffset}`);
    } else {
      // Target is a time zone identifier
      const utcDate = new Date(dateString + "Z"); // Convert to UTC
      targetDate = new Date(
        utcDate.toLocaleString("en-US", { timeZone: targetTimezoneOrOffset })
      );
    }

    return targetDate;
  }

  static toISOStringWithOffset(date: Date): string {
    const { year, month, day, hours, minutes, seconds, milliseconds } =
      DateUtils.deconstruct(date);

    // Get the original timezone offset in hours and minutes
    const timezoneOffset = -date.getTimezoneOffset(); // Note the negative sign
    const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60)
      .toString()
      .padStart(2, "0");
    const offsetMinutes = (Math.abs(timezoneOffset) % 60)
      .toString()
      .padStart(2, "0");
    const offsetSign = timezoneOffset >= 0 ? "+" : "-";
    const originalOffset = `${offsetSign}${offsetHours}:${offsetMinutes}`;

    // Construct the ISO 8601 string with the original offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${originalOffset}`;
  }
}
